// @codekit-prepend "utils.js"

jQuery(function($) {
	/*--------------------------
	 *
	 *  MOBILE DETECT
	 *
	 *--------------------------*/
	var TOUCH = "ontouchstart" in window;

	var isMobile = $(window).innerWidth() <= 1024 ? true : false;

	$(window).smartresize(function() {
		isMobile = $(window).innerWidth() <= 1024 ? true : false;
	});

	/*--------------------------
	 *
	 *  WORKS ARCHIVE - IMAGE HOVER
	 *
	 *--------------------------*/
	$("body").on("mouseenter mouseleave", ".works-item", function(e) {
		if (!TOUCH) {
			var $dis = $(this);
			var works_id = $dis.data("id");
			var $thumb = $(".works-thumb-" + works_id);
			if ($thumb.length) {
				if (e.type == "mouseenter") {
					$thumb.addClass("is-active");
				} else {
					$thumb.removeClass("is-active");
				}
			}
		}
	});

	/*--------------------------
	 *
	 *  KEY PRESS
	 *
	 *--------------------------*/
	if (!TOUCH) {
		document.addEventListener("keyup", function(event) {
			if (event.defaultPrevented) {
				return;
			}

			var key = event.key || event.keyCode;

			if (key === "i" || key === 73) {
				toggleDarkMode();
			}
		});
	}
	function toggleDarkMode() {
		$("body").toggleClass("is-dark");
	}
	function enableDarkMode() {
		$("body").addClass("is-dark");
	}
	function disableDarkMode() {
		$("body").removeClass("is-dark");
	}
	$("body").on("click", ".toggle-dark", toggleDarkMode);

	function setColorScheme() {
		const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
			.matches;
		const isLightMode = window.matchMedia("(prefers-color-scheme: light)")
			.matches;
		const isNotSpecified = window.matchMedia(
			"(prefers-color-scheme: no-preference)"
		).matches;
		const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

		window
			.matchMedia("(prefers-color-scheme: dark)")
			.addListener(e => e.matches && enableDarkMode());
		window
			.matchMedia("(prefers-color-scheme: light)")
			.addListener(e => e.matches && disableDarkMode());

		if (isDarkMode) enableDarkMode();
		if (isLightMode) disableDarkMode();
		if (isNotSpecified || hasNoSupport) {
			console.log("no color scheme specified");
		}
	}
	//setColorScheme();

	/*--------------------------
	 *
	 *  QUICKLINK
	 *
	 *--------------------------*/
	window.addEventListener("load", () => {
		quicklink.listen();
	});

	/*--------------------------
	 *
	 *  FLICKITY CAROUSEL
	 *
	 *--------------------------*/
	function initSlider() {
		var $carousel = jQuery(".carousel:not(.flickity-enabled)");
		if ($carousel.length) {
			var $next = $carousel
				.next(".carousel-nav")
				.find(".carousel-nav-next");
			var $prev = $carousel
				.next(".carousel-nav")
				.find(".carousel-nav-prev");
			var $count = jQuery(".carousel-count");
			var flickity;
			flickity = new Flickity(".carousel", {
				draggable: TOUCH,
				freeScroll: false,
				wrapAround: true,
				prevNextButtons: false,
				pageDots: false,
				fade: true,
				lazyLoad: 1,
				adaptiveHeight: false,
				imagesLoaded: true,
				cellSelector: ".carousel-image",
				setGallerySize: false,
				contain: false,
				cellAlign: "right",
				selectedAttraction: 0.01,
				friction: 0.15,
				on: {
					ready: function() {
						jQuery(".carousel").focus();
						checkSlideVideos(1);
					},
					change: function(index) {
						updateSlideCount(index);
						checkSlideVideos(+index + 1);
					}
				}
			});
			$next.on("click", function() {
				flickity.next();
			});
			$prev.on("click", function() {
				flickity.previous();
			});

			// Click + swipe on mobile
			if (TOUCH) {
				$(".carousel").on("staticClick.flickity", function(
					event,
					pointer,
					cellElement,
					cellIndex
				) {
					var px = parseInt(pointer.pageX),
						ww = window.innerWidth;
					if (px <= ww / 2) {
						// Prev
						flickity.previous();
					} else {
						// Next
						flickity.next();
					}
				});
			}

			// Counter
			function updateSlideCount(index) {
				if ($count.length) {
					var cellNumber = index + 1;
					$count.html(cellNumber + "/" + flickity.slides.length);
				}
			}

			// Video playback
			function checkSlideVideos(index) {
				$(".carousel-image:not(.is-selected) video").each(function() {
					var $dis = $(this);
					$dis.get(0).pause();
				});
				$(".carousel-image.is-selected video").each(function() {
					var $dis = $(this);
					if (this.paused) {
						this.play();
					}
				});

				// Time update tracking
				$(".carousel-image video:not(.is-tracked)").each(function() {
					var $dis = $(this);
					var dis = this;
					$dis.addClass("is-tracked");
					dis.addEventListener(
						"timeupdate",
						function() {
							if (
								$dis.parents(".carousel-image.is-selected")
									.length
							) {
								$(".button-progress span.progress").html(
									calcVideoProgress(dis.currentTime)
								);
								$(".button-progress span.remaining").html(
									calcVideoLeft(dis.currentTime, dis.duration)
								);
							}
						},
						false
					);
				});

				// Toggle mute button
				if (jQuery(".carousel-video.is-selected").length) {
					jQuery(".button-mute, .button-progress").addClass(
						"is-active"
					);
				} else {
					jQuery(".button-mute, .button-progress").removeClass(
						"is-active"
					);
				}
			}

			updateSlideCount(0);
		}
	}

	// Video time calculations
	function calcVideoProgress(time) {
		var s = parseInt(time % 60);
		var m = parseInt((time / 60) % 60);
		if (s < 10) {
			s = "0" + s;
		}
		if (m < 10) {
			m = "0" + m;
		}
		return m + ":" + s;
	}
	function calcVideoLeft(time, duration) {
		var s = parseInt((duration - time) % 60);
		var m = parseInt(((duration - time) / 60) % 60);
		if (s < 10) {
			s = "0" + s;
		}
		if (m < 10) {
			m = "0" + m;
		}
		return m + ":" + s;
	}

	// Mute button toggle
	$("body").on("click", ".button-mute", function() {
		var $dis = $(this);
		$dis.toggleClass("is-muted");
		$("video").each(function() {
			this.muted = $dis.hasClass("is-muted");
		});
	});

	// Progress toggle
	$("body").on("click", ".button-progress", function() {
		var $dis = $(this);
		$dis.toggleClass("is-remaining");
	});

	// Init slider on load
	// Remove loader
	$(window).on("load", function() {
		initSlider();
		$("#loader").fadeOut(function() {
			$(this).remove();
		});
	});
});

/*--------------------------
 *
 *  FLICKITY FIX
 *
 *--------------------------*/
(function() {
	var touchingCarousel = false,
		touchStartCoords;

	document.body.addEventListener("touchstart", function(e) {
		if (e.target.closest(".flickity-slider")) {
			touchingCarousel = true;
		} else {
			touchingCarousel = false;
			return;
		}

		touchStartCoords = {
			x: e.touches[0].pageX,
			y: e.touches[0].pageY
		};
	});

	document.body.addEventListener(
		"touchmove",
		function(e) {
			if (!(touchingCarousel && e.cancelable)) {
				return;
			}

			var moveVector = {
				x: e.touches[0].pageX - touchStartCoords.x,
				y: e.touches[0].pageY - touchStartCoords.y
			};

			if (Math.abs(moveVector.x) > 7) e.preventDefault();
		},
		{ passive: false }
	);
})();

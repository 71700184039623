window.lazySizesConfig = {
	loadMode: 3,
	expand: 1000
};

jQuery(function($) {
	/*-------------
	 *
	 *  SMARTRESIZE
	 *  https://gist.github.com/raspo/9755313
	 *
	 *-------------*/

	(function($, sr) {
		// debouncing function from John Hann
		// http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
		var debounce = function(func, threshold, execAsap) {
			var timeout;

			return function debounced() {
				var obj = this,
					args = arguments;

				function delayed() {
					if (!execAsap) func.apply(obj, args);
					timeout = null;
				}

				if (timeout) clearTimeout(timeout);
				else if (execAsap) func.apply(obj, args);

				timeout = setTimeout(delayed, threshold || 100);
			};
		};
		// smartresize
		jQuery.fn[sr] = function(fn) {
			return fn ? this.bind("resize", debounce(fn)) : this.trigger(sr);
		};
	})(jQuery, "smartresize");

	/*-------------
	 *
	 *  Remove Comments
	 *
	 *-------------*/

	function removeComments() {
		$("*")
			.contents()
			.each(function() {
				if (this.nodeType === Node.COMMENT_NODE) {
					$(this).remove();
				}
			});
	}
	removeComments();

	/*-------------
	 *
	 *  FUNCIONES UTILES
	 *
	 *-------------*/

	// validateEmail(email)
	function validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	// enlaces externos
	// $(this).isExternal()
	$.fn.isExternal = function() {
		var host = window.location.host;
		var link = $("<a>", {
			href: this.attr("href")
		})[0].hostname;
		if ($(this).attr("target") == "_blank") return true;
		return link !== host;
	};
});
